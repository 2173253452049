<template>
  <AuthContainer @onBack="handleBack" title="忘記密碼">
    <AuthBackContainer title="忘記密碼">
      <section class="flex flex-col justify-between h-full md:p-8 lg:px-24 md:shadow-pink md:rounded-xl">
        <section class="forms-basic">
          <article>
            <div class="block h-inherit white-panel md:h-auto">
              <!--pc 外框-->
              <div class="block h-inherit md:flex md:justify-center md:max-w-930 md:mx-auto">
                <a-form-model ref="ruleForm" :model="form" :rules="rules" class="block  white-panel h-inherit">
                  <div class="flex flex-col justify-between container-wrapper no-padding h-inherit">
                    <section class="forms-basic justify-center">
                      <div class="md:m-4 lg:mx-12 ">
                        <p class="item-title"><span>手機</span></p>
                        <a-form-model-item prop="mobile" ref="mobile">
                          <a-input type="text" placeholder="請輸入你的手機" :maxLength="10" v-model="form.mobile" autocomplete="nope" />
                        </a-form-model-item>
                      </div>

                      <div class="md:m-4 lg:mx-12" v-if="isVerifying">
                        <p class="item-title"><span>檢核碼</span><span class="ml-4 text-sm opacity-50">檢核碼已送出,請查看簡訊</span></p>
                        <a-form-model-item prop="varify" ref="varify">
                          <div class="relative">
                            <a-input type="text" :maxLength="10" v-model="form.verify" placeholder="請輸入檢核碼" autocomplete="nope" />
                            <button class="custom-mobile-btn" :disabled="countDownLocked" @click="handleGetSMS">
                              <vac :left-time="60000" ref="vacCountDown">
                                <span slot="stopped">重發驗證碼</span>
                                <span slot="process" slot-scope="{ timeObj }"> {{ timeObj.ceil.s }} 秒等待 {{ getCountDownSec(timeObj.ceil.s) }} </span>
                                <span slot="finish">重發驗證碼</span>
                              </vac>
                            </button>
                          </div>
                        </a-form-model-item>
                      </div>

                      <div class="md:m-4 lg:mx-12 ">
                        <p class="item-title"><span>密碼</span></p>
                        <a-form-model-item prop="password" ref="password">
                          <a-input type="password" placeholder="密碼須為6字以上英數字" v-model="form.password" autocomplete="new-password" />
                        </a-form-model-item>
                      </div>
                      <div class="md:m-4 lg:mx-12 ">
                        <p class="item-title"><span>確認密碼</span></p>
                        <a-form-model-item prop="confirmPassword" ref="confirmPassword">
                          <a-input type="password" placeholder="密碼須為6字以上英數字" v-model="form.confirmPassword" autocomplete="new-password" />
                        </a-form-model-item>
                      </div>

                      <div class="hidden md:block md:m-4 lg:mx-12 ">
                        <!--pc-->
                        <!-- <button class="btn-forms btn-block" @click="handleResetPassword">test</button> -->

                        <button @click="handleSubmit" class="btn-forms btn-block" :disabled="!isRequirePassed">送出</button>
                      </div>
                    </section>
                  </div>
                </a-form-model>
              </div>
            </div>
          </article>

          <article class="md:hidden white-panel">
            <!--mobile-->
            <button @click="handleSubmit" class="btn-forms btn-block" :disabled="!isRequirePassed">送出</button>
          </article>
        </section>
      </section>
    </AuthBackContainer>
  </AuthContainer>
</template>

<script>
import AuthContainer from '@/components/auth/AuthContainer'
import AuthBackContainer from '@/components/auth/AuthBackContainer'
import { mapActions,mapGetters } from 'vuex'
import { regExpPassword, regExpMobile } from '@/utils/regExp.js'
//regExpMobile
export default {
  name: 'reset',
  components: {
    AuthContainer,
    AuthBackContainer
  },
  data: function() {
    return {
      form: {
        mobile: '',
        password: '',
        verify: '',
        confirmPassword: '',
      },
      rules: {
        mobile: { required: true, validator: (rule, value, callback) => this.validatorVerify(value, callback, '手機號碼輸入格式為 0987654321'), trigger: 'change' },
        verify: { required: true, message: '請輸入手機檢核碼', trigger: 'change' },
        password: { required: true, pattern: regExpPassword, message: '密碼6-20字, 英文大小寫數字至少1個', trigger: 'change' },
        confirmPassword: { required: true, validator: (rule, value, callback) => this.validatorComparePassword(value, callback, '兩者密碼不相同'), trigger: 'change' }
      },
      isVerifying: '', //手機有沒有認證並滿10數字
      countDownSec: '',
      countDownLocked: true
    }
  },
  methods: {
    ...mapActions(['resetPassword', 'getVerifySMS']),
    getCountDownSec(sec) {
      //套件倒數的秒數
      this.countDownSec = sec
    },
    validatorVerify(value, callback, errorText) {
      const reg = regExpMobile
      if (!reg.test(value)) {
        callback(errorText)
      } else if (reg.test(value)) this.isVerifying = true
      callback()
    },
    validatorComparePassword(value, callback, errorText) {
      const password = this.form.password
      if (value != password) {
        callback(errorText)
      }
      callback()
    },
    handleBack() {
      this.$router.push('/')
    },
    handleGetSMS() {
      // * api 取手機簡訊
      let formData = new FormData()
      let value = this.form.mobile
      console.log(value)
      formData.append('mobile', value)
      formData.append('check_exist', false)
      this.$nextTick(function () {
        this.$refs.vacCountDown.startCountdown(true)
      });
      this.getVerifySMS(formData) //取得手機驗證碼
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.handleResetPassword()
        } else {
          console.log('表格驗證有錯誤')
          return false
        }
      })
    },
    handleResetPassword() {
      // * api  修改密碼
      let formData = new FormData()
      formData.append('mobile', this.form.mobile)
      formData.append('password', this.form.password)
      formData.append('verify', this.form.verify)
      formData.append('timestamp', this.verifyTimestamp)

      // for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]); 
      // }

      this.resetPassword(formData)
    }
  },
  computed: {
    ...mapGetters(['verifyTimestamp']),
    isRequirePassed() {
      return (this.form.password !== '') & (this.form.confirmPassword !== '') & (this.form.mobile !== '') & (this.form.verify !== '')
    },
  },
  watch: {
    countDownSec: {
      handler(value) {
        if (value === 1) {
          setTimeout(() => {
            this.countDownLocked = false
          }, 1000)
        } else this.countDownLocked = true
      }
    },
    isVerifying: {
      handler(value) {
        if (value == true) {
          this.handleGetSMS()
        }
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
.custom-mobile-btn {
  @apply absolute right-0 top-0 rounded-2xl px-4;
  background: #fff;
  border: #513e41 1px solid;
  color: #513e41;
  height: 36px;
  line-height: 1;
}
.custom-mobile-btn:disabled {
  background: #e0e0e0;
  border: 0;
}
.custom-circle {
  padding: 0;
  width: 44px;
  height: 40px;
}

.max-w-930 {
  max-width: 930px;
}
.max-w-490 {
  max-width: 490px;
}
</style>
